import React from 'react';
import { ExExpandableRowGroupsTable } from '../../../../components/Table/ExpandableRowGroupsTable';
import { Column } from 'primereact/column';
import { GeneralNames } from './../../../../namesConstants/names';
import './table.css';

export const TableReportExpandedAttendance = ({ service }) => {
	const columnsConfig = [
		{
			field: 'employeeStatus',
			header: 'Estatus',
			style: { width: '150px' },
		},
		{
			field: 'employeeShift',
			header: 'Turno',
			style: { width: '150px' },
		},
		{
			field: 'calendarDay',
			header: 'Fecha',
			style: { width: '150px' },
		},
		{
			field: 'incidenceDescription',
			header: 'Incidencia',
			style: { width: '300px' },
		},
		{
			field: 'checkIn',
			header: 'Entrada',
			style: { width: '150px' },
		},
		{
			field: 'checkInPlace',
			header: 'Ubicación de entrada',
			style: { width: '250px' },
		},
		{
			field: 'checkOut',
			header: 'Salida',
			style: { width: '150px' },
		},
		{
			field: 'checkOutPlace',
			header: 'Ubicación de Salida',
			style: { width: '250px' },
		},

		{
			field: 'incidenceDurationFmt',
			header: 'Asistencia',
			style: { width: '150px' },
		},
		{
			field: 'delayAttendanceFmt',
			header: 'Retardo',
			style: { width: '150px' },
		},
	];

	const headerTemplate = (row) => {
		return (
			<span className='image-text font-karla font-bold'>
				{`${row.employeeCode} - ${row.employeeName} ${
					row.employeeDepartment !== '' ? '- ' + row.employeeDepartment : ''
				}`}
			</span>
		);
	};

	const renderBody = (rowData) => {
		return rowData !== null ? (
			<p title={rowData} className='truncate !text-sm !font-karla'>
				{rowData}
			</p>
		) : null;
	};

	const calculateTotalByLabel = (item, label) => {
		let total = 0;
		let rows = service.expandableRowTableQuery.filter(
			(row) => row.employeeName === item.employeeName
		);

		rows.forEach((row) => {
			total += row[label];
		});

		let hours = Math.floor(total / 60);
		let minutes = total % 60;
		return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
			2,
			'0'
		)}`;
	};

	const footerTemplate = (data) => {
		return (
			<div className='grid grid-cols-12 gap-4'>
				<td className='flex col-span-9 justify-end'>
					<p className='font-bold'>{'Total de asistencias'}</p>
				</td>
				<td>{calculateTotalByLabel(data, 'incidenceDuration')}</td>
				<td>
					<p className='font-bold'>{'Total de retardos'}</p>
				</td>
				<td>{calculateTotalByLabel(data, 'delayAttendance')}</td>
			</div>
		);
	};

	const headerClassNameColumn =
		'hover:!text-success  !bg-secondary-PCS004 !text-secondary-PCS001 !font-semibold !border-r-2 !border-secondary-PCS003 !font-bold !text-[14px] !font-karla ';
	const bodyClassName =
		' !border-r-2 !border-b-0 !border-secondary-PCS004 !text-black !font-karla !text-sm';

	return (
		<div className='flex w-full h-full relative'>
			<ExExpandableRowGroupsTable
				key={'keyTable'}
				value={service.expandableRowTableQuery}
				sortMode='single'
				groupRowsBy='employeeCode'
				filters={service.filters}
				globalFilterFields={[
					'employeeShift',
					'calendarDay',
					'checkIn',
					'checkOut',
					'checkInPlace',
					'checkOutPlace',
					'incidenceDescription',
					'incidenceDurationFmt',
					'delayAttendanceFmt',
					'employeeStatus',
					'employeeName',
				]}
				footerTemplate={footerTemplate}
				scrollable
				size='small'
				scrollHeight='flex'
				headerTemplate={headerTemplate}
				columnResizeMode='fit'
				emptyMessage={GeneralNames.GeneralEmptyMessage}
				className='!flex !flex-col !w-full !h-full !relative !bg-transparent'
				tableStyle={{
					height: '100% !important',
					minHeight: '100% !important',
					background: 'none',
					backgroundColor: 'none',
				}}
				tableClassName='!overflow-scroll !flex-col !absolute inset-0'
				rowClassName='hover:!bg-[#3b82f6] !border-r !border-secondary-PCS004'
				paginatorClassName='!border-t !border-border !justify-start !text-xs !top-0 flex bg-withe'
				paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
				currentPageReportTemplate='Total de registros {totalRecords}'
				responsiveLayout='scroll'>
				{columnsConfig.map((col) => (
					<Column
						key={col.field}
						headerClassName={headerClassNameColumn}
						field={col.field}
						bodyClassName={bodyClassName}
						header={col.header}
						style={col.style}
						body={(rowData) => renderBody(rowData[col.field])}
					/>
				))}
			</ExExpandableRowGroupsTable>
		</div>
	);
};
