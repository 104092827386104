import React, { useContext, useEffect, useState } from 'react';
import { MainContainer } from '../../components/MainContainer';

import { FilterComponent } from './components/filter/FiltersComponent';

import './components/table/index.css';

import { TableQueriesComponent } from './components/table/TableQueriesComponent';
import { IconsPanelControl } from '../../components/IconsPanelControl';
import { Button } from 'primereact/button';

import { SearchComponent } from '../../components/SearchComponent';

import { QueriesServiceManager } from '../../context/wrapperContext/queries/FilterContext';
import { TwoContainerComponent } from '../../components/Containers/TwoRowContainer';
import { TableReportExpandedAttendance } from './components/table/TableReportExpandendAttendance';
import { useIntegrations } from '../../hooks/integrations/useIntegrations';
import { StoreContext } from '../../business/Provider';
import useCompanySetting from '../../hooks/company/useCompanySetting';
import { Toast } from '../../components/toast';
export const QueriesComponent = () => {
	const serviceContext = useContext(QueriesServiceManager);
	const { postIntegrations } = useIntegrations();
	const { stateCompanyDataSettings } = useContext(StoreContext);
	const { getCompanySettings } = useCompanySetting();
	const [retry, setRetry] = useState(0);

	useEffect(() => {
		const fetchData = async () => {
			const attendanceImportDetail =
				stateCompanyDataSettings?.companies?.attendanceImportDetail;
			const data = JSON.parse(attendanceImportDetail);
			try {
				if (
					data.ReEntryData === undefined ||
					data.ReEntryData === null ||
					!data.ReEntryData
				) {
					postIntegrations(208).then((result) => {
						if (result.status === 200) {
							Toast('success', 'Importación exitosa');
							setRetry(retry + 1);
							return getCompanySettings();
						}
					});
				}
				// Ambas peticiones se ejecutan al mismo tiempo
			} catch (error) {
				console.error('Error fetching data:', error); // Maneja posibles errores
			}
		};

		if (retry < 3) fetchData(); // Invoca la función asincrónica
	}, []);

	useEffect(() => {
		serviceContext.setUpdateTable(!serviceContext.updateTable);
	}, []);

	const leftComponent = () => {
		return <FilterComponent service={serviceContext} />;
	};

	const renderTable = () => {
		switch (serviceContext.filterTypeQuerySelect.code) {
			case 'R':
				// Render the expanded attendance report table
				return <TableReportExpandedAttendance service={serviceContext} />;

			case 'C':
			case 'I':
				// Render the queries table component for cases 'C' and 'I'
				return (
					<TableQueriesComponent
						tableDataQuery={serviceContext.tableDataQuery}
						dynamicColumns={serviceContext.dynamicColumns}
						filters={serviceContext.filters}
						sort={serviceContext.sort}
						setSort={serviceContext.setSort}
						keyTable={serviceContext.updateTable}
						service={serviceContext}
					/>
				);

			default:
				// Render nothing for unsupported keys
				return null;
		}
	};

	const rightComponent = () => {
		return (
			<div className='h-full flex flex-col w-full ml-1 border rounded-md shadow-2xl'>
				<div className='flex w-full h-12 flex-row '>
					<div className='flex h-full w-1/3 items-center'>
						<h1 className='text-2xl font-karla font-bold ml-2'>
							{serviceContext.nameQuery}
						</h1>
					</div>
					<div className='flex h-full w-1/3 p-1 '>
						<SearchComponent
							clearTextLabel={serviceContext.clearTextLabel}
							onChange={serviceContext.onGlobalFilterChange}
							value={serviceContext.globalFilterValue}
						/>
					</div>
					<div className='flex h-full w-1/3'>
						<div className='flex w-4/5 p-1 '>
							{serviceContext.filterTypeQuerySelect.code !== 'R' ? (
								<Button
									onClick={() =>
										serviceContext.setShowColumnsDate(
											!serviceContext.showColumnsDate
										)
									}
									label={
										serviceContext.showColumnsDate
											? 'Ver resumen'
											: 'Ver detalle'
									}
									icon={
										serviceContext.showColumnsDate
											? 'pi pi-eye-slash'
											: 'pi pi-eye'
									}
									className='!w-full !text-xs !h-full !truncate'
									disabled={
										serviceContext.tableDataQuery === null ||
										serviceContext.tableDataQuery.length === 0
									}
								/>
							) : null}
						</div>
						<div className='flex w-1/5 p-1'>
							<IconsPanelControl iconsData={serviceContext.iconsData} />
						</div>
					</div>
				</div>
				<div className='flex w-full h-full flex-grow  relative p-1'>
					{renderTable()}
				</div>
			</div>
		);
	};
	const renderBodyMainContainer = () => {
		return (
			<TwoContainerComponent
				leftComponent={leftComponent()}
				rightComponent={rightComponent()}
			/>
		);
	};

	return (
		<MainContainer cancelTittle iNeedFullLoading={serviceContext.loadingState}>
			<div className='flex w-full h-full flex-row p-1 border relative'>
				{renderBodyMainContainer()}
			</div>
		</MainContainer>
	);
};
