/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import { StoreContext } from '../../../business/Provider';
import {
	LOADING_ON,
	LOADING_OFF,
	GET_COLLABORATORS_WORK_CENTER_SUCCESS,
	GET_WORK_CENTER_SUCCESS,
	GET_WORK_CENTER_BY_ID_SUCCESS,
	POST_WORK_CENTER_ERROR,
	GET_WORK_CENTER_BY_ID_ERROR,
	PATCH_WORK_CENTER,
	PATCH_WORK_CENTER_ERROR,
	DELETE_WORK_CENTER,
	DELETE_WORK_CENTER_ERROR,
	PUT_WORK_CENTER,
	PUT_WORK_CENTER_ERROR,
	GET_WORK_CENTER,
} from '../../../business/constants';
import { generateUrlBase } from '../../../utils/utils';
import useApi from '../../api';
import { Toast } from '../../../components/toast';

const useWorkCenter = () => {
	const context = useContext(StoreContext);
	const { genericApiCall } = useApi();
	const [workCenters, setWorkCenters] = useState(undefined);
	const [collaboratorsWorkCenter, setCollaboratorsWorkCenter] = useState(null);
	const {
		getWorkCenterState,
		dispatchGetWorkCenter,
		dispatchLoading,
		dispatchGetCollaboratorsWorkCenter,
		dispatchPostWorkCenter,
		dispatchPatchWorkCenter,
		dispatchDeleteWorkCenterById,
		dispatchPutStatusWorkCenter,
	} = context;

	const getWorkCenters = async (page = 1, pageSize = 25) => {
		const baseUrl = generateUrlBase(
			`work-center?Page=${page}&PageSize=${pageSize}`
		);
		dispatchGetWorkCenter({
			type: GET_WORK_CENTER,
		});
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const payload = result.data;
			if (result.status === 200) {
				dispatchGetWorkCenter({
					type: GET_WORK_CENTER_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
				setWorkCenters(payload);
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const getCollaboratorsByWorkCenter = async (page = 1, pageSize = 25) => {
		const baseUrl = generateUrlBase(
			`work-center/collaborators?Page=${page}&PageSize=${pageSize}`
		);
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const payload = result.data;
			if (result.status === 200) {
				dispatchGetCollaboratorsWorkCenter({
					type: GET_COLLABORATORS_WORK_CENTER_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
				setCollaboratorsWorkCenter(payload);
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};
	const postWorkCenter = async (WorkCenter) => {
		const baseUrl = generateUrlBase(`work-center`);
		dispatchLoading({ type: LOADING_ON });

		return await genericApiCall(baseUrl, 'POST', WorkCenter)
			.then((result) => {
				if (result.status === 200 || result.status === 201) {
					console.log('result', result);
					Toast(
						'success',
						'Se ha creado el centro de trabajo de forma correcta'
					);

					return getWorkCenters();
				} else if (result.status === 409) {
					Toast('warning', result.data.detail);
				}
			})
			.catch((error) => {
				Toast('warning', error.message, '', error);
				dispatchPostWorkCenter({ type: POST_WORK_CENTER_ERROR });
				dispatchLoading({ type: LOADING_OFF });
			})
			.finally(() => {
				dispatchLoading({ type: LOADING_OFF });
			});
	};

	const getWorkCenterById = async (WorkCenterId) => {
		const baseUrl = generateUrlBase(`work-center/${WorkCenterId}`);
		try {
			dispatchLoading({ type: LOADING_ON });
			const result = await genericApiCall(baseUrl, 'GET', {}, {});
			if (result.status === 200) {
				const payload = result.data;
				dispatchGetWorkCenter({
					type: GET_WORK_CENTER_BY_ID_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
				return result;
			} else {
				let message = {
					body: '',
					header: 'Error al crear el centro de trabajo',
				};
				dispatchGetWorkCenter({
					type: GET_WORK_CENTER_BY_ID_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
				return undefined;
			}
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchGetWorkCenter({ type: GET_WORK_CENTER_BY_ID_ERROR });
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const patchWorkCenter = async (WorkCenter) => {
		const baseUrl = generateUrlBase(`work-center`);

		dispatchLoading({ type: LOADING_ON });
		return await genericApiCall(baseUrl, 'PATCH', WorkCenter, {})
			.then((result) => {
				if (result.status === 200) {
					Toast('success', 'El centro de trabajo se ha actualizado');
					const payload = result.data;
					dispatchPatchWorkCenter({
						type: PATCH_WORK_CENTER,
						payload: payload,
					});
					return getWorkCenters();
				} else if (result.status === 409) {
					Toast('warning', result.data.detail);
				}
			})
			.catch((error) => {
				console.log('error', error);
				Toast('warning', error.message, '', error);
				dispatchPatchWorkCenter({ type: PATCH_WORK_CENTER_ERROR });
				dispatchLoading({ type: LOADING_OFF });
			})
			.finally(() => {
				dispatchLoading({ type: LOADING_OFF });
			});

		// } catch (error) {

		// }
	};

	const deleteWorkCenter = async (id) => {
		const baseUrl = generateUrlBase(`work-center/${id}`);
		dispatchLoading({ type: LOADING_ON });
		return await genericApiCall(baseUrl, 'DELETE', {}, {})
			.then((result) => {
				if (result.status === 200) {
					const payload = result;
					dispatchDeleteWorkCenterById({
						type: DELETE_WORK_CENTER,
						payload: payload,
					});
					dispatchLoading({ type: LOADING_OFF });
					Toast('success', 'Se ha eliminado el centro de trabajo');
					return result;
				} else if (result.status === 409) {
					Toast('warning', result.data.detail);
				}
			})
			.catch((error) => {
				Toast('warning', error.message, '', error);
				dispatchDeleteWorkCenterById({ type: DELETE_WORK_CENTER_ERROR });
				dispatchLoading({ type: LOADING_OFF });
			})
			.finally(() => {
				dispatchLoading({ type: LOADING_OFF });
			});
	};

	const putStatusWorkCenter = async (id) => {
		const baseUrl = generateUrlBase(`work-center/switch-status/${id}`);
		try {
			dispatchLoading({ type: LOADING_ON });
			const result = await genericApiCall(baseUrl, 'PUT', {}, {});
			if (result.status === 200) {
				Toast('success', 'Se ha actualizado el estatus del centro de trabajo');
				const payload = result;
				dispatchPutStatusWorkCenter({
					type: PUT_WORK_CENTER,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
				return result;
			} else {
				let message = {
					body: '',
					header: 'Error al cambair el estatus del centro de trabajo',
				};
				dispatchPutStatusWorkCenter({
					type: PUT_WORK_CENTER_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
				return undefined;
			}
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchPutStatusWorkCenter({ type: DELETE_WORK_CENTER_ERROR });
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	useEffect(() => {
		if (
			getWorkCenterState.workCenters === null &&
			getWorkCenterState.loading === false
		)
			getWorkCenters();
	}, [getWorkCenterState.workCenters]);

	return {
		getWorkCenterState,
		workCenters,
		getWorkCenters,
		getCollaboratorsByWorkCenter,
		collaboratorsWorkCenter,
		postWorkCenter,
		getWorkCenterById,
		patchWorkCenter,
		deleteWorkCenter,
		putStatusWorkCenter,
	};
};

export default useWorkCenter;
